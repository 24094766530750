<template>
  <div>
    <div class="">
      <h5 class="text-black">
        <strong>Pengirim</strong>
      </h5>
      <div>Pengirim Broadcast</div>
    </div>
    <div class="mt-2">
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label-cols-md="3">
              <template #label>
                <h6 class="text-black">
                  <span class="text-primary">*</span> Judul Template
                </h6>
              </template>
              <b-form-input
                :value="templateName"
                placeholder="Masukan judul template"
                @input="$emit('update:templateName',$event)"
              />
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template #label>
                <h6 class="text-black">
                  <span class="text-primary">*</span> Pilih Nomor Pengirim
                </h6>
              </template>
              <v-select
                :options="optionSenderNumber"
                label="label"
                placeholder="Pilih Nomor Pengirim"
                @input="$emit('update:senderNumber', $event)"
              >
                <template #selected-option="data">
                  <div
                    v-if="data.id !== 0"
                    class="flex gap-1"
                  >
                    {{ data.label }} - <div class="italic font-sm">
                      {{ data.type === 'komchat'? 'Komchat Lite': 'Komchat for Brands' }}
                    </div> <img
                      v-if="data.type !== 'komchat'"
                      src="https://storage.googleapis.com/komerce/assets/illustration/check-blue.svg"
                      alt="blue-check"
                    >
                  </div>
                </template>
                <template #option="data">
                  <b-button
                    v-if="data.id === 0"
                    size="sm"
                    variant="primary"
                  >
                    {{ data.label }}
                  </b-button>
                  <div
                    v-if="data.id !== 0"
                    class="flex gap-1"
                  >
                    {{ data.label }} - <div class="italic font-sm">
                      {{ data.type === 'komchat'? 'Komchat Lite': 'Komchat for Barands' }}
                    </div> <img
                      v-if="data.type !== 'komchat'"
                      src="https://storage.googleapis.com/komerce/assets/illustration/check-blue.svg"
                      alt="blue-check"
                    >
                  </div>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template #label>
                <h6 class="text-black">
                  <span class="text-primary">*</span> Pilih Tipe Template
                </h6>
              </template>
              <v-select
                v-model="typeTemplateMessage"
                :disabled="disabledSelectTypeMessage"
                :options="optionsTemplate"
                :selectable="(option) => option.value !== 'utility' && option.value !== 'authentication' "
                label="label"
                placeholder="Pilih Tipe Template"
                @input="$emit('update:typeTemplateMessage', $event)"
              >
                <template #option="data">
                  <div v-if="data.description">
                    <span class="text-semibold">{{ data.label }}</span> - <span>{{ data.description }}</span>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormCheckbox,
  BSpinner,
  BModal,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormGroup,
    VSelect,
  },
  props: {
    templateName: {
      type: String,
      default: '',
    },
    senderNumber: {
      type: [String, Object],
      default: () => {},
    },
    optionsTemplate: {
      type: Array,
      default: () => [],
    },
    optionSenderNumber:
    {
      type: Array,
      default: () => [],
    },
    typeTemplateMessage: {
      type: Object,
      default: () => {},
    },
    disabledSelectTypeMessage: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    senderNumber: {
      handler(newValue) {
        if (!newValue || (Array.isArray(newValue) && newValue.length === 0)) {
          this.typeTemplateMessage = null
        }
        this.typeTemplateMessage = null
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
