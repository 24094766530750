var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"template-notification"}},[_c('div',{staticClass:"flex p-[24px]  items-center gap-2 "},[_c('b-img',{staticClass:"align-self-start w-[2.8rem] md:w-[unset] custom-profile",attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/profile-placehold.svg"}}),_c('div',{staticClass:"custom-template tri-right left-top"},[(
          _vm.idTemplate === 0 ||
            _vm.idTemplate === 2
        )?_c('div',{class:_vm.content
            ? 'max-w-full lg:max-w-[33rem] w-[100%] lg:w-[33rem] bg-white p-1 rounded-lg'
            : 'd-none'},[_c('fieldset',{staticClass:"text-left min-w-[100%] w-full bg-white max-w-[430px] rounded-lg ",attrs:{"id":"preview-message"},domProps:{"innerHTML":_vm._s(_vm.content)}})]):_vm._e(),(
          _vm.idTemplate === 1 ||
            _vm.idTemplate === 2
        )?_c('div',{class:_vm.caption || _vm.urlMediaLocal
            ? 'bg-white   mt-[5px]  p-1 rounded-lg max-w-full lg:max-w-[33rem] w-[100%] lg:w-[33rem]'
            : 'd-none'},[_c('b-img',{staticClass:"object-contain max-w-[100%]",attrs:{"fluid":"","src":_vm.urlMediaLocal,"alt":""}}),_c('fieldset',{staticClass:"text-left min-w-[100%] w-full bg-white max-w-[430px] rounded-lg ",attrs:{"id":"preview-message"},domProps:{"innerHTML":_vm._s(_vm.caption)}})],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }