<template>
  <b-row>
    <b-col>
      <div>
        <div>
          <div>Pilih tipe Konten</div>
          <div class="my-1">
            <v-select
              v-model="typeContent"
              :options="optionsTypeContentForBrands"
              label="label"
              placeholder="Pilih Tipe Konten"
            />
          </div>
        </div>
        <div
          v-if="typeContent? typeContent.id === 1 : null"
          class=" mb-1 mt-1"
        >
          <div class="text-[11.07px] mb-[3px]">
            <span class="text-primary">*</span>Foto
          </div>
          <b-input-group>
            <b-input-group-append
              class="flex border rounded-md border-[#cfcfcf] border-1 pl-[8px] items-center w-full"
              :class="error.media ? 'border-primary' : ''"
              accept="image/png, image/jpeg"
              @click="$refs.photoFile.click()"
            >
              <div
                class="flex bg-primary rounded-sm p-[3px] items-center  w-[98px]"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-upload.svg"
                  alt=""
                  width="15px"
                >
                <span class="ml-[2px] text-[12px] text-white">
                  Pilih Foto</span>
              </div>
              <b-form-input
                v-model="mediaName"
                class=" bg-white"
                style="border: none;"
                :readonly="true"
                :placeholder="mediaName ? mediaName : 'upload Foto'"
              />
            </b-input-group-append>
            <input
              id="poster"
              ref="photoFile"
              class="form-control"
              type="file"
              accept="image/png, image/jpeg"
              style="display: none"
              @change="validateExt"
            >
          </b-input-group>
          <div class="text-[9.22px] mt-[5px] text-[#828282]">
            <span class="text-primary">*</span>Upload foto dengan maksimal 1 Mb
          </div>
        </div>
        <div
          class="border border-1 mt-1 rounded-bl-0 rounded-br-0 rounded-md p-[1.3rem]"
        >
          <div v-if="typeContent? typeContent.id === 0 : null">
            Tambah Variabel<span class="text-primary">*</span>
          </div>
          <div v-if="typeContent? typeContent.id === 1 : null">
            Caption
          </div>
        </div>
        <div
          class="border  border-1 border-t-0 rounded-md rounded-tl-0 rounded-tr-0 p-[1.3rem]"
        >
          <UiInputHtml
            :variable="true"
            :editor-options="editorOptionCaption"
            :toolbars-id="'caption'"
            :custom-variable="additionalVariableForBrands"
            :content="content"
            @update:content="handleContent"
          />
        </div>
      </div>
    </b-col>
    <b-col>
      <div class="text-black md:hidden mt-3 mb-1 font-semibold">
        Preview
      </div>
      <div class="">
        <TemplatePreview
          :caption="caption"
          :content="content"
          :id-template="typeContent? typeContent.id : null"
          :url-media-local="urlMediaLocal"
        />
      </div>
      <b-overlay :show="broadcast.loading">
        <b-button
          :disabled="disableSendBroadcastButton()"
          class="w-full my-2"
          variant="primary"
          size="sm"
          @click="submitTemplate"
        >

          <div class="flex gap-1 justify-center items-center">
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/send-2.svg"
              alt=""
            />
            <div class="text-sm">
              Ajukan Template
            </div>
          </div>
        </b-button>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import VSelect from 'vue-select'
import UiInputHtml from './UiInputHtml.vue'
import TemplatePreview from './TemplatePreview.vue'
import { apiSaveTempImageForBrand, apiRequestBroadcastForBrand } from '../service/api.broadcast.service'
import { additionalVariableForBrands, editorOptionCaption, optionsTypeContentForBrands } from '../config'

export default {
  components: {
    UiInputHtml,
    VSelect,
    TemplatePreview,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    numberId: {
      type: Number,
      default: 0,
    },
    phoneNumber: {
      type: Number,
      default: 0,
    },
    templateType: {
      type: String,
      default: '',
    },
    groupContact: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
      additionalVariableForBrands,
      editorOptionCaption,
      optionsTypeContentForBrands,
      error: {
        media: '',
      },
      mediaName: '',
      media: null,
      urlMediaLocal: '',
      mediaHandler: '',
      urlMedia: '',
      typeContent: null,
      broadcast: {
        loading: false,
      },
    }
  },
  methods: {
    validateExt(evChange) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
      ) {
        this.error.media = 'Hanya dapat upload file dengan ekstensi JPG dan PNG'
        this.mediaName = ''
      } else {
        this.mediaName = evChange.target.files[0].name
        this.validateSize(evChange)
      }
    },
    validateSize(evChange) {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size > maxSize) {
          this.error.media = 'Ukuran file terlalu besar. Maksimal 1 MB.'
          this.media = {}
          this.mediaName = ''
        } else {
          this.media = file
          this.urlMediaLocal = URL.createObjectURL(file)
        }
      }
    },
    async handleSaveImageTemp() {
      try {
        const formData = new FormData()
        formData.append('file', this.media)
        const { data } = await apiSaveTempImageForBrand({ formData })
        this.urlMedia = data.data.media_url
        this.mediaHandler = data.data.media_handler
      } catch (error) {
        this.$toast_error({ message: 'Gagal Menyimpan Image' })
        console.error(error)
      }
    },
    handleContent(value) {
      if (this.typeContent) {
        if (this.typeContent.id === 0) {
          this.content = value
        } else {
          this.caption = value
          this.content = value
        }
      }
    },
    async submitTemplate() {
      try {
        this.broadcast.loading = true
        if (this.typeContent.value === 'MEDIA') {
          await this.handleSaveImageTemp()
        }
        const params = {
          title: await this.replaceSpacesWithUnderscore(this.title),
          type: this.typeContent.value,
          contents: await this.convertHtmlToWhatsapp(this.content),
          template_type: this.templateType,
          sender_phone_id: this.numberId,
          phone_number: this.phoneNumber,
          media_url: this.urlMedia,
          media_handler: this.mediaHandler,
          group_contact_ids: this.groupContact,
        }
        await apiRequestBroadcastForBrand({ params })
        this.$router.push({ name: 'broadcast', params: { type: 'brand' } })
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengajukan Template' })
      } finally {
        this.broadcast.loading = false
      }
    },
    disableSendBroadcastButton() {
      return !this.groupContact || !this.numberId || !this.phoneNumber || !this.templateType || this.disableByType()
    },

    disableByType() {
      const type = this.typeContent.value
      const disabled = {
        TEXT: !this.content || !this.title,
        MEDIA: !this.content || !this.urlMediaLocal || !this.title,
      }
      return disabled[type]
    },
    convertHtmlToWhatsapp(htmlText) {
    // Mengganti tag paragraf dengan baris baru
      let plainText = htmlText.replace(/<\/?p>/g, match => (match === '</p>' ? '\n' : ''))
      // Mengganti <br> dengan baris baru
      plainText = plainText.replace(/<br\s*\/?>/g, '\n')
      // Mengganti tag <strong> dengan tanda bintang
      plainText = plainText.replace(/<\/?strong>/g, '*')
      // Mengganti tag <em> dengan underscore
      plainText = plainText.replace(/<\/?em>/g, '_')
      // Mengganti tag <s> dengan tilde
      plainText = plainText.replace(/<\/?s>/g, '~')
      // Mengganti tag <li> dengan tanda titik dan baris baru
      plainText = plainText.replace(/<\/?li>/g, match => (match === '</li>' ? '' : '\n- '))
      // Mengganti tag <ul> dan </ul> dengan spasi baru untuk menjaga format list
      plainText = plainText.replace(/<\/?ul>/g, '')
      // Mengganti tag <ol> dan </ol> dengan baris baru
      plainText = plainText.replace(/<\/?ol>/g, '\n')
      // Mengganti spasi di dalam tag
      plainText = plainText.replace(/>\s+</g, '><')
      // Mengganti spasi di dalam tag ganda
      plainText = plainText.replace(/<strong>\s*<s>(.*?)<\/s>\s*<\/strong>/g, '*~$1~*')
      plainText = plainText.replace(/<em>\s*<strong>\s*<s>(.*?)<\/s>\s*<\/strong>\s*<\/em>/g, '_*~$1~*_')
      // Mengatasi spasi di dalam tag triple
      plainText = plainText.replace(/<em>\s*<strong>\s*<s>\s*(.*?)\s*<\/s>\s*<\/strong>\s*<\/em>/g, '_*~$1~*_')
      // Mengganti tag yang nested dengan urutan bebas
      plainText = plainText.replace(/<em>\s*<strong>(.*?)<\/strong>\s*<\/em>/g, '_*$1*_')
      plainText = plainText.replace(/<strong>\s*<em>(.*?)<\/em>\s*<\/strong>/g, '*_$1_*')
      plainText = plainText.replace(/<em>\s*<s>(.*?)<\/s>\s*<\/em>/g, '_~$1~_')
      plainText = plainText.replace(/<s>\s*<em>(.*?)<\/em>\s*<\/s>/g, '~_$1_~')
      plainText = plainText.replace(/<strong>\s*<s>(.*?)<\/s>\s*<\/strong>/g, '*~$1~*')
      plainText = plainText.replace(/<s>\s*<strong>(.*?)<\/strong>\s*<\/s>/g, '~*$1*~')
      // Menjaga spasi di dalam teks
      plainText = plainText.replace(/(\S)(<[^>]+>)(\s+)(<[^>]+>)(\S)/g, '$1$2 $4$5')
      // Menghapus tag HTML yang tersisa
      plainText = plainText.replace(/<\/?[^>]+(>|$)/g, '')
      return plainText.trim()
    },

    replaceSpacesWithUnderscore(str) { return str.replace(/ /g, '_').toLowerCase() },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
